import React, { useEffect, useState } from "react";
import { Button, Row, Col} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../../helper/axios";
import toast from 'react-hot-toast';

const Dynamicform = () => {
  const { formState: { errors } } = useForm();
  const [addMedicine, setaddMedicine] = useState([
    { medicines:'', dosage:'', frequency:'', timeperiod:'', refill:'', duration:''}
  ]);
  const { patientId } = useParams();

  const handleChange = (index,event) => {
    const values = [...addMedicine];
    if(event.target.name === 'medicines'){
      values[index].medicines = event.target.value
    } else if(event.target.name === 'dosage'){
      values[index].dosage = event.target.value
    } else if(event.target.name === 'frequency'){
      values[index].frequency = event.target.value
    }else if(event.target.name === 'timeperiod'){
        values[index].timeperiod = event.target.value
    }else if(event.target.name === 'refill'){
      values[index].refill = event.target.value
    }else if(event.target.name === 'duration'){
      values[index].duration = event.target.value
    }

    setaddMedicine(values)
  }
  
  const handleAddFields = () => {
    const values = [...addMedicine];
    values.push({medicines:'', dosage:'', frequency:'', timeperiod:'', refill:'', duration:''})
    setaddMedicine(values);
  };

  const handleRemoveFields = () => {
    const values = [...addMedicine];
    if(values.length > 1)  values.pop();
    setaddMedicine(values);
  };

  const handleSubmit = async (e) => {
    console.log("convert ===== ", JSON.stringify(addMedicine, null, 2))
    console.log("addMedicine === ", addMedicine)

    let  obj = [];
    addMedicine.forEach((element, index, array) => { 
        let res = {
            "name":element.medicines,
            "dosage":element.dosage,
            "frequency":element.frequency,
            "timePeriod":element.timeperiod,
            "refill": element.refill,
            "duration": element.duration
        }
        obj.push(res);
        console.log("Element = ",element); // 100, 200, 300    console.log(index); // 0, 1, 2    console.log(array); // same myArray object 3 times
    });


    e.preventDefault()
   const data = {
    "patientId":patientId,
    "medicines":obj
}

var valid = true;
addMedicine.forEach((element, index, array) => {
    if(element.medicines === ""){ 
        valid = false;
    }

})
// if(data.diagnosis === ""){ 
//     valid = false;
// }  
if(valid){
   var res = await axios.post(`/add-prescription`,data);
    //alert(JSON.stringify(addMedicine, null, 2))
    //alert(patientId)
    console.log("res ===== ", res)
    if(res.status===200){    
        toast.success('Prescription added successfully');
    }else{
        toast.error('Perscription add process failed');
    }
} else {
    toast.success('Please fill required fields marked with *.');
}
   
  }
  
  return (
    <form className="form-horizontal">
      {
        addMedicine.map((data,i) => {
          return (
            <Row className='mt-3' key={i}>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-2 control-label">Drug *</label>
                            <div className="col-sm-10">
                            <input type="text" className="form-control" id="title" placeholder="" name="medicines" value={data.medicines} onChange={event => handleChange(i,event)} required/>

                            </div> 
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-2 control-label">Size</label>
                            <div className="col-sm-10">
                            <input type="text" className="form-control" id="title" placeholder="" name="dosage" value={data.dosage} onChange={event => handleChange(i,event)} required/>
                            {errors.dosage && <span className='validationError'>Required</span>}
                            </div> 
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-2 control-label">SIG ( how to take )</label>
                            <div className="col-sm-10">
                            <input type="text" className="form-control" id="title" placeholder="" name="frequency" value={data.frequency} onChange={event => handleChange(i,event)} required/>
                            {errors.frequency && <span className='validationError'>Required</span>}
                            </div> 
                        </div>
                        <div className="form-group">
                            <label htmlFor="duration" className="col-sm-2 control-label">Duration</label>
                            <div className="col-sm-10">
                            <input type="number" className="form-control" id="duration" placeholder="Enter number of days" name="duration" value={data.duration} onChange={event => handleChange(i,event)} required/>
                            {errors.duration && <span className='validationError'>Required</span>}
                            </div> 
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-2 control-label">Dispense(quantity)</label>
                            <div className="col-sm-10">
                            <input type="text" className="form-control" id="title" placeholder="" name="timeperiod" value={data.timeperiod} onChange={event => handleChange(i,event)} required/>
                            {errors.timeperiod && <span className='validationError'>Required</span>}
                            </div> 
                        </div>
                        <div className="form-group">
                            <label htmlFor="refill" className="col-sm-2 control-label">Refills</label>
                            <div className="col-sm-10">
                            <input type="text" className="form-control" id="refill" placeholder="" name="refill" value={data.refill} onChange={event => handleChange(i,event)} required/>
                            {errors.refill && <span className='validationError'>Required</span>}
                            </div> 
                        </div>
            </Row>
          )
        })
      }
    <Row>
      <Col className='pt-3 d-flex justify-content-between'>
        <Button variant="warning" onClick={handleAddFields}>Add More Medicine</Button>
        <Button variant="danger" onClick={handleRemoveFields}>Remove </Button>
      </Col>
  </Row>
  {/* <div className="form-group">
                            <label htmlFor="title" className="col-sm-2 control-label">Diagnosis *</label>
                            <div className="col-sm-10">
                            <input type="text" className="form-control" id="title" placeholder="" name="diagnosis" value={diagnosis} onChange={(e)=>setdiagnosis(e.target.value)} required/>
                            {errors.diagnosis && <span className='validationError'>Required</span>}
                            </div> 
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="title" className="col-sm-2 control-label">Do Not Use</label>
                            <div className="col-sm-10">
                            <input type="text" className="form-control" id="title" placeholder="" name="doNotUse" value={doNotUse} onChange={(e)=>setdoNotUse(e.target.value)}/>
                            {errors.doNotUse && <span className='validationError'>Required</span>}
                            </div> 
  </div> */}
  <Button className='mt-5' variant="primary" type="submit" onClick={handleSubmit}>
    Submit
  </Button>
</form>
  )
}

export default Dynamicform;