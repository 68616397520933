import {prescriptionStatus} from '../action/Status';

const initialData = {
	data:Number,
};
const newData = {
	data: []
}

export default (state=initialData,action,newState=newData)=>{
	 console.log(action)
	 switch(action.type){
	 	case prescriptionStatus.prescription_REQUEST:
	 	    state={
	 	    	...state
 			}
	 	break; 
	 	case prescriptionStatus.PRESCRIPTION_GET_REQUEST:
	 	    state={
	 	    	...state
 			}
	 	break;
	 	case prescriptionStatus.PRESCRIPTION_GET_SUCCESS:
	 	    state={
	 	    	...state,
	 	    	data:action.payload.prescriptions

	 	    }
	 	break;
         case prescriptionStatus.PRESCRIPTION_GET_FAILED:
            state={
                ...state
            }
        break;
		case prescriptionStatus.NEW_PRESCRIPTION_GET_SUCCESS:
	 	    state={
	 	    	...state,
				newData:action.payload.newPrescriptions
	 	    }
	 	break;
		 case prescriptionStatus.NEW_PRESCRIPTION_UPDATE_REQUEST:
			state={
				...state
			}
		break;
	   case prescriptionStatus.NEW_PRESCRIPTION_UPDATE_SUCCESS:
		   state={
			//    ...state,
			   ...newState
		   }
	   break;
	   case prescriptionStatus.NEW_PRESCRIPTION_UPDATE_FAILED:
			state={
				...state
			}
		break;	
		case prescriptionStatus.PRESCRIPTION_UPDATE_REQUEST:
			state={
				...state
			}
		break;
	   case prescriptionStatus.PRESCRIPTION_UPDATE_SUCCESS:
		   state={
			//    ...state,
			   ...newState
		   }
	   break;
	   case prescriptionStatus.PRESCRIPTION_UPDATE_FAILED:
			state={
				...state
			}
		break;	
	 }

	 return state;
}