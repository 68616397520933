import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import Header from '../../header/Header';
import Sidebarr from '../../sidebar/Sidebar';
import Tool from '../../sidebar/Tool';
import Footer from '../../footer/Footer';
import { ChangePasswordAction} from "../../action";
import {useDispatch,useSelector} from "react-redux";
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";

export default function ChangePassword() {

const dispatch = useDispatch();
const { formState: { errors } } = useForm();
const adminId = useSelector(state=>state?.auth?.user);
const id = adminId?._id;

useEffect(async()=>{
  
},[]);

const [email,setEmail] = useState("");
const [newPassword,setNewPassword] = useState("");
const [confirmPassword,setConfirmPassword] = useState("");

const onSubmit = async() => { 
  let count = 0;
  if(confirmPassword !== newPassword){
    count = count+1;
    toast.error("Password and Confirm Password are not same");
  }
  if(confirmPassword.length < 6 || newPassword.length < 6){
    count = count+1;
    toast.error("Password should be of minimum 6 digits");
  }
  if(!(email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))){
    count = count+1;
    toast.error("Invalid Email Address");
  }
  if(count == 0){
    const data={
      id,
      email,
      newPassword
    }
     await dispatch(ChangePasswordAction(data)).then(()=>{
      setEmail("");
      setNewPassword("");
      setConfirmPassword("");
     }
     );
  }
   
    
}

	return (
    <div className="sidebar-mini skin-green-light">
        <div><Toaster/></div>
    <div className="wrapper"> 
  		  <Header/> 
  		  <Sidebarr/> 
    <div className="content-wrapper"> 
    <section className="content-header">
      <h1>
       Change Password
      </h1>
      <ol className="breadcrumb">
        <li>
        <Link to="/home" className="fa fa-dashboard"> Home</Link>
        </li>
        <li>
        <Link to="/change-password" className="small-box-footer">Change Password</Link>
        </li>
        {/* <li style={{fontWeight:"bold"}}>Add Profile</li> */}
      </ol>
    </section> 
    <section className="content"> 
        <div className="box">
            

            <div className="box-body">
            <section className="content">
            <div className="row">  
                <div className="col-md-12">
                <div className="nav-tabs-custom">
                    <div className="tab-content">
                    <div className="active tab-pane" id="settings">
                        <div className="form-horizontal">
                        <div className="form-group" style={{marginLeft:"20%"}}>
                            <label htmlFor="email" className="col-sm-2 control-label">Email</label>
                            <div className="col-sm-6">
                            <input type="text" className="form-control" id="email" placeholder="Enter Email Id" name="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                            {errors.email && <span className='validationError'>Required</span>}
                            </div> 
                        </div>

                        <div className="form-group" style={{marginLeft:"20%"}}>
                            <label htmlFor="newPassword" className="col-sm-2 control-label">New Password</label>
                            <div className="col-sm-6">
                            <input type="password" min={6} className="form-control" id="newPassword" placeholder="Enter New Password" name="newPassword" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
                            {errors.newPassword && <span className='validationError'>Required</span>}
                            </div> 
                        </div>
    
                      <div className="form-group" style={{marginLeft:"20%"}}>
                            <label htmlFor="confirmPassword" className="col-sm-2 control-label">Confirm Password</label>
                            <div className="col-sm-6">
                            <input type="password" min={6} value={confirmPassword} className="form-control" id="confirmPassword" placeholder="Re-enter New Password" name="confirmPassword" onChange={(e)=>setConfirmPassword(e.target.value)}/>
                            {errors.confirmPassword && <span className='validationError'>Required</span>}
                            </div>
                        </div> 

                        <div className="form-group" style={{marginLeft:"29%"}}>
                            <div className="col-sm-offset-2 col-sm-10">
                            <button type="button" className="btn btn-danger" onClick={(e)=>onSubmit()}>Update Password</button>
                            </div>
                        </div>
                        </div>
                    </div> 
                    </div> 
                </div> 
                </div> 
            </div> 
            </section> 
            </div>
       </div> 
    </section> 
  </div> 
  <Footer/>
       <Tool/> 
  <div className="control-sidebar-bg" />
</div>		
    </div>
	)
}