import React,{useEffect,useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import Header from '../../../header/Header';
import Sidebarr from '../../../sidebar/Sidebar';
import { EditPrescriptionStatusAction, GetNewPrescriptionAction } from "../../../action"
import Tool from '../../../sidebar/Tool';
import Footer from '../../../footer/Footer';
import {useSelector,useDispatch} from "react-redux";
import { Toaster } from 'react-hot-toast';
import axios,{baseUrl} from "../../../helper/axios";
import { Button } from 'react-bootstrap';

export default function NotificationList() {
  const auth = useSelector(state=>state.auth);
  const prescriptionData = useSelector(state=>state?.prescription?.newData?.data)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(async()=>{
    // const newpres = await GetNewPrescriptionAction();
    await dispatch(GetNewPrescriptionAction())

  },[])

  const changeStatus = async(status, prescriptionId, patientId) => { 
    const data={
        status : status,
        prescriptionId : prescriptionId
   } 
   await dispatch(EditPrescriptionStatusAction(data));
   if(status === false){
    console.log("---------")
    navigate(`/addprescription/${patientId}`);

    // this.props.history.push(`/addprescription/${patientId}`);
    // return <Redirect to='/addprescription/{patientId}' />
   }
   await refreshPrescriptionList();
    
 }

 const refreshPrescriptionList = async() => {
  await dispatch(GetNewPrescriptionAction());
}
	return (
		<div className="sidebar-mini skin-green-light">
      <div><Toaster/></div>
		<div className="wrapper"> 
  		  <Header/> 
  		  <Sidebarr/>
         
  {/* Content Wrapper. Contains page content */}
  <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <section className="content-header">
      <h1>
      Unapproved Prescriptions List
      </h1>
      <ol className="breadcrumb">
        <li>
          <a href="/home">
            <i className="fa fa-dashboard" /> Home
          </a>
        </li>
        <li>
          <a style={{fontWeight:"bold"}}>Manage Unapproved Prescriptions</a>
        </li>
      </ol>
    </section>
    {/* Main content */}
    <section className="content">
      {/* Default box */}
      <div className="box">

        <div className="box-body">
        	<table className="table table-bordered table-striped dataTable" id="example2">
        	  <thead>
        	    <tr>
                    <th>Title</th>
                    <th>Action</th>
                    <th>Approve/Reject</th>
        	  	</tr>
        	  </thead>
        	  <tbody>
            {prescriptionData?.length > 0 && prescriptionData?.map((dt)=>(
              <tr>
                {/* {(() => {
                    if (
                      dt?.isRead === false
                    ) {
                      return (
                        <td>
                        <span className="text-uppercase fs-1" style={{color:"red",fontWeight:"bold"}}><p>{dt?.title}</p></span>
                        </td>
                    
                    );}
                    else { */}
                      {/* return ( */}
                      <td>
                        <span className="text-uppercase fs-1" style={{fontWeight:"bold"}}><p>{dt?.patientId?.name} has a new prescription</p></span>
                      </td>
                      {/* ); */}
                    {/* }})()} */}
                  
                <td>
                <Link title="View BP Chart" to={"/view-patient-bp-chart/"+dt?.patientId?._id} className="btn btn-default">
                  <i className="fa fa-bar-chart" />
                </Link>
                {" "} 
                {" "}
                <a href={baseUrl+"prescriptions/"+dt?.pdf} download={"prescriptions/"+dt?.pdf} target='_blank'>
                    <button title="Download Prescription" className="btn btn-default">
                        <i className="fa fa-download"></i>
                    </button>
                </a>
                {" "} 
                {" "}
                <Link title="View Patient" to={"/viewpatient/"+dt?.patientId?._id} className="btn btn-default">
                  <i className="fa fa-eye" />
                </Link>
                {" "} 
                {" "}
                </td>
                <td>
                    <Button onClick={(e)=>changeStatus(true, dt?._id, dt?.patientId?._id)}>Approve</Button>
                    {" "} 
                    {" "}
                    <Button variant="danger" onClick={(e)=>changeStatus(false, dt?._id, dt?.patientId?._id)}>Reject</Button>
                </td>
              </tr>
              ))}

              {(() => {
                if (
                  prescriptionData?.length === 0
                ) {
                  return (
                    <tr>
                      <td colspan="3">
                        <h6 style={{padding:"11px", textAlign:"center"}}>No Records Found</h6>
                      </td>
                    </tr>
                    )
                }
              })()}
        	  </tbody>
        	</table>
        </div>
        {/* /.box-body */}
        {/* /.box-footer*/}
      </div>
      {/* /.box */}
    </section>
    {/* /.content */}
  </div>
  {/* /.content-wrapper */}
       <Footer/>
       <Tool/> 
  <div className="control-sidebar-bg" />
</div>	
</div>
)}