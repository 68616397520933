import React, { useEffect, useState } from 'react'
import {useSelector,useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import moment from 'moment'

export default function DashboardCard() {
  const currentDate = new Date();
  const adminRole = useSelector(state=>state?.auth?.user?.role);
  const prescriptionData = useSelector(state=>state?.prescription?.data?.data);
  const diseaseData = useSelector(state=>state?.disease?.data?.data);
  const doctorData = useSelector(state=>state?.doctor?.data?.data);
  const patientData = useSelector(state=>state?.patient?.data);
  const notificationData = useSelector(state=>state?.notification?.data?.data);
  // if(notificationData){
  //   notificationData.forEach(element => {
  //     var elapsed = current - previous;
  //   });
  // }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
 
	return (
		<div>
		    <section className="content-header">
      <h1>
        Dashboard
        <small>Control panel</small>
      </h1>


      {/* <div className="container">
        <h2>Modal Example</h2>
        <button type="button" className="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Open Modal</button>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">×</button>
                <h4 className="modal-title">Modal Header</h4>
              </div>
              <div className="modal-body">
                <p>Some text in the modal.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      
      
      <ol className="breadcrumb">
        <li>
          <a href="#">
            <i className="fa fa-dashboard" /> Home
          </a>
        </li>
        <li style={{fontWeight:"bold"}}>Dashboard</li>
      </ol>
    </section>
    {/* Main content */}
    <section className="content" style={{minHeight: "213px"}}>
      {/* Small boxes (Stat box) */}
      <div className="row">
        <div className="col-lg-3 col-xs-6">
          {/* small box */}
          <div className="small-box" style={{backgroundColor: '#73cadf',color: 'white'}}>
            <div className="inner">
              <h3>{patientData?.length}</h3>
              <p>Total Patients</p>
            </div>
            <div className="icon">
              <i className="ion-android-people" />
            </div>
            
            <Link to="/patients" className="small-box-footer">
              More info <i className="fa fa-arrow-circle-right" />
            </Link>

          </div>
        </div>
        {(() => {
          if (
            adminRole === "Admin"
          ) {
            return (
            <div className="col-lg-3 col-xs-6">
              {/* small box */}
              <div className="small-box" style={{backgroundColor: '#48e19b',color: 'white'}}>
                <div className="inner">
                  <h3>{prescriptionData}</h3>
                  <p>Total Prescriptions</p>
                </div>
                <div className="icon">
                  <i className="ion-ios-pulse-strong" />
                </div>
                
                <Link to="/home" className="small-box-footer">
                  More info <i className="fa fa-arrow-circle-right" />
                </Link>

              </div>
            </div>
            )
            
            }
          })()}
          {(() => {
          if (
            adminRole === "Doctor" || adminRole === "Admin"
          ) {
            return (
            <div className="col-lg-3 col-xs-6">
              {/* small box */}
              <div className="small-box" style={{backgroundColor: '#9b96e7',color: 'white'}}>
                <div className="inner">
                  <h3>{notificationData?.length}</h3>
                  <p>Total Notifications</p>
                </div>
                <div className="icon">
                  <i className="fa fa-bell" />
                </div>
                
                <Link to="/notifications" className="small-box-footer">
                  More info <i className="fa fa-arrow-circle-right" />
                </Link>

              </div>
            </div>
            )
            
            }
          })()}
        {/* {(() => {
          if (
            adminRole === "Admin"
          ) {
            return (
        <div className="col-lg-3 col-xs-6">
          <div className="small-box" style={{backgroundColor: '#9b96e7',color: 'white'}}>
            <div className="inner">
              <h3>{diseaseData?.length}</h3>
              <p>Total Diseases</p>
            </div>
            <div className="icon">
              <i className="fa fa-stethoscope" />
            </div>
            
            <Link to="/diseases" className="small-box-footer">
              More info <i className="fa fa-arrow-circle-right" />
            </Link>

          </div>
        </div>
            )
        }
      })()} */}

    {(() => {
          if (
            adminRole === "Admin"
          ) {
            return (
        <div className="col-lg-3 col-xs-6">
          {/* small box */}
          <div className="small-box" style={{backgroundColor: '#d2e375',color: 'white'}}>
            <div className="inner">
              <h3>{doctorData?.length}</h3>
              <p>Total Doctors</p>
            </div>
            <div className="icon">
              <i className="fa fa-user-md" />
            </div>
            
            <Link to="/doctors" className="small-box-footer">
              More info <i className="fa fa-arrow-circle-right" />
            </Link>

          </div>
        </div>
            )
        }
      })()}
      </div>

    </section>

    <div className="row">
        {/* Left col */}
        <section className="col-lg-7 connectedSortable" style={{paddingLeft:"30px"}}>

          {/* TO DO List */}
          <div className="box box-primary">
            <div className="box-header row">
              <div className='col-sm-10'>
                <i className="ion ion-clipboard" />
                <h3 className="box-title" style={{fontWeight:"bold",paddingLeft:"10px"}}>Notifications</h3>
              </div>
              <div className='col-sm-2'>
                <Link title="View" to="/notifications/">
                <p className="box-title" style={{fontWeight:"light", color:"gray"}}>View all</p>
                </Link>
              </div>
            </div>
            <div className="box-body">
              <ul className="todo-list">
              {notificationData?.length>0 && notificationData?.slice(0,6).map((dt)=>(
                <li>
                  <span className="handle">
                    <i className="fa fa-ellipsis-v" />
                    <i className="fa fa-ellipsis-v" />
                  </span>
                  <span className="text">{dt?.title}</span>
                  <small className="label label-danger" style={{fontSize:"10px"}}>
                    <i className="fa fa-clock-o" /> { moment(new Date(dt?.createdAt)).fromNow()};
                  </small>
                  <div className="tools">
                  <Link title="View" to={"/view-notification/"+dt?._id} className="btn btn-default" 
                  style={{background:"transparent", border:"transparent", marginTop:"-6px"}}>
                    <i className="fa fa-eye" />
                  </Link>
                  </div>
                </li>
              ))}

            {(() => {
              if (
                notificationData?.length === 0
              ) {
                return (
                  // <tr>
                  //   <td colspan="3">
                      <p style={{textAlign:"left"}}>No Notifications Found</p>
                  //   </td>
                  // </tr>
                  )
              }
            })()}
                
                {/* <li>
                  <span className="handle">
                    <i className="fa fa-ellipsis-v" />
                    <i className="fa fa-ellipsis-v" />
                  </span>
                  <span className="text">Consent provided by patient</span>
                  <small className="label label-info">
                    <i className="fa fa-clock-o" /> 4 hours
                  </small>
                  <div className="tools">
                    <i className="fa fa-eye" />
                  </div>
                </li>
                <li>
                  <span className="handle">
                    <i className="fa fa-ellipsis-v" />
                    <i className="fa fa-ellipsis-v" />
                  </span>
                  <span className="text">Patient BP in the category of Hypertension</span>
                  <small className="label label-warning">
                    <i className="fa fa-clock-o" /> 1 day
                  </small>
                  <div className="tools">
                    <i className="fa fa-eye" />
                  </div>
                </li>
                <li>
                  <span className="handle">
                    <i className="fa fa-ellipsis-v" />
                    <i className="fa fa-ellipsis-v" />
                  </span>
                  <span className="text">New patient registered</span>
                  <small className="label label-success">
                    <i className="fa fa-clock-o" /> 3 days
                  </small>
                  <div className="tools">
                    <i className="fa fa-eye" />
                  </div>
                </li>
                <li>
                  <span className="handle">
                    <i className="fa fa-ellipsis-v" />
                    <i className="fa fa-ellipsis-v" />
                  </span>
                  <span className="text">
                    Check your messages and notifications
                  </span>
                  <small className="label label-primary">
                    <i className="fa fa-clock-o" /> 1 week
                  </small>
                  <div className="tools">
                    <i className="fa fa-eye" />
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </section>
      </div>	
		</div>
	)
}