import React,{useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import Header from '../../../header/Header';
import Sidebarr from '../../../sidebar/Sidebar';
import {GetPatientAction,DeletePatientAction} from "../../../action"
import Tool from '../../../sidebar/Tool';
import Footer from '../../../footer/Footer';
import {useSelector,useDispatch} from "react-redux";
import { Toaster } from 'react-hot-toast';


export default function ProductList() {
  const [input, setInput] = useState('');
  const auth = useSelector(state=>state.auth);
  const patientData = useSelector(state=>state?.patient?.data)
  const [countryList, setCountryList] = useState(patientData);
  const handleSearch = async (input) => {
    const filtered = patientData.filter(country => {
      let searchRes = (country?.name?.toLowerCase().includes(input.toLowerCase())) || 
                      (country?.email?.toLowerCase().includes(input.toLowerCase())) ||
                      (country?.mobile?.toLowerCase().includes(input.toLowerCase()));
     return searchRes;
    })
    setInput(input);
    setCountryList(filtered);
 }
 const handleStatus = async (input) => {
  console.log("---input-----",input);
  let filtered;
  if(input === "all"){
    filtered = patientData.filter(country => {
      let searchRes = (country?.isDeleted == false) || (country?.isDeleted == true);
      //let hyperUncontrolled = (country?.systolic >= 200) || (country?.diastolic >= 115);
     return searchRes;
    })
  }
  if(input === "active"){
    filtered = patientData.filter(country=>country.isDeleted == false)
  }
  if(input === "deleted"){
    filtered = patientData.filter(country=>country.isDeleted == true)
  }
  if(input === "uncontrolled"){
    filtered = patientData.filter(country=>{
      let unSearch = ((country?.systolic >= 200 || country?.diastolic >= 115));
      return unSearch;
    })
  }

  if(input === "elevated"){
    filtered = patientData.filter(country=>{
      let unSearch = (country?.systolic >= 150 && country?.systolic < 200 && country?.diastolic < 115) || (country?.diastolic >= 90 && country?.diastolic < 115 && country?.systolic < 200);
      return unSearch;
    })
  }

  if(input === "controlled"){
    filtered = patientData.filter(country=>{
      let unSearch = (country?.systolic > 0 && country?.systolic < 150  && country?.diastolic < 90) || (country?.diastolic > 0 && country?.diastolic < 90 && country?.systolic < 150);
      return unSearch;
    })
  }

  if(input === "inactive"){
    filtered = patientData.filter(country=>{
      let unSearch = (country?.systolic == 0) || (country?.diastolic == 0);
      return unSearch;
    })
  }
  
  console.log("filtered:",filtered);
  // setInput(input);
  setCountryList(filtered);
  // dispatch(GetPatientAction(input))
}
  const dispatch = useDispatch();
  useEffect(()=>{
     dispatch(GetPatientAction())
  },[])


  const deletePatient=async(id)=>{
    const data={id:id}
    await dispatch(DeletePatientAction(data))
    await refreshPatientList();
    window.location.reload();
  }

  const refreshPatientList = async() => {
    await dispatch(GetPatientAction());
  }

	return (
		<div className="sidebar-mini skin-green-light">
      <div><Toaster/></div>
		<div className="wrapper"> 
  		  <Header/> 
  		  <Sidebarr/>
         
  {/* Content Wrapper. Contains page content */}
  <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <section className="content-header">
      <h1>
        Patients  List
        {/* <small>it all starts here</small> */}
      </h1>
      <ol className="breadcrumb">
        <li>
          <a href="/home">
            <i className="fa fa-dashboard" /> Home
          </a>
        </li>
        <li style={{fontWeight:"bold"}}>Manage patients</li>
      </ol>
    </section>
    {/* Main content */}
    <section className="content">
      {/* Default box */}
      <div className="box">
        <div className="box-header with-border"> 
        <input
          style={{width:"25rem",background:"#F2F1F9", border:"3px solid #ccc", padding:"0.5rem", borderRadius: "6px"}}
          placeholder={"Search..."}
          value={input}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <select
        style={{width:"25rem",background:"#F2F1F9", border:"3px solid #ccc", padding:"0.5rem", borderRadius: "6px", marginLeft: "10px"}}
          onChange={(e)=>handleStatus(e.target.value)}>
              <option value='all'>All</option>
              {/*<option value='active'>Active</option>
              <option value='deleted'>Deleted</option>*/}
              <option value='uncontrolled'>Hypertension Uncontrolled</option>
              <option value='elevated'>Hypertension Being Elevated</option>
              <option value='controlled'>Hypertension Controlled</option>
              <option value='inactive'>Inactive Patients</option>
          {/* {genderData.map(({ value, name }, index) => <option value={name} >{value}</option>)} */}
        </select>
          <div className="box-tools pull-right">
            <Link
              to="/addpatient"
              type="button"
              className="btn btn-warning" 
              data-toggle="tooltip"
              title="Add"
            >
              <i className="fa fa-plus" />
              {" Add New patient "}
            </Link>
            {" "}
          </div>
        </div>
        <div className="box-body">
          <table className="table table-bordered" style={{width:"450px"}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:"rgb(255, 102, 102)", color:"black", textAlign:"center"}}><b>Hypertension Uncontrolled</b></td>
                <td style={{backgroundColor:"rgb(128, 128, 255)", color:"black", textAlign:"center"}}><b>Hypertension Being Elevated</b></td>
                <td style={{backgroundColor:"rgb(0, 255, 128)", color:"black", textAlign:"center"}}><b>Hypertension Controlled</b></td>
                <td style={{backgroundColor:"rgb(255, 255, 153)", color:"black", textAlign:"center"}}><b>Inactive Patients</b></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="box-body">
        	<table className="table table-bordered table-striped">
        	  <thead>
        	    <tr style={{backgroundColor:"rgb(194, 214, 214)"}}>
        	  	<th className="fw-bold">S. No.</th>
              
        	  	<th>Name</th>
              <th>Age</th> 
              <th>State</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Status</th>
        	  	<th>Action</th>
        	  	</tr>
        	  </thead>
        	  <tbody>
            {countryList?.length>0  && countryList?.map((dt,index)=>{
              var colorVal = ''
              if(dt.systolic >= 200 || dt.diastolic >= 115){ //Uncontrolled  
                  colorVal = "rgb(255, 102, 102)" //Uncontrolled
              } else if((dt.systolic >= 150 && dt.systolic < 200 && dt.diastolic < 115) || (dt.diastolic >= 90 && dt.diastolic < 115 && dt.systolic < 200) ) {  
                  colorVal = "rgb(128, 128, 255)" //Elevated
              } else if((dt.systolic > 0 && dt.systolic < 150 && dt.diastolic < 90) || (dt.diastolic > 0 && dt.diastolic < 90 && dt.systolic < 150)){
                  colorVal = "rgb(0, 255, 128)" //Controlled 
              }else if(dt.systolic == 0 && dt.diastolic == 0){
                colorVal = "rgb(255, 255, 153)" //Inactive
              } else {
                colorVal = "white"
              }

              if(dt.isDeleted == false){
                return(
                  <tr style={{backgroundColor:colorVal, color:"black"}}>
                    <td key={index+1}>{index+1}</td>
                    <td>
                      <span className="text-uppercase fs-1">
                        <p>{dt?.name?dt.name:'NA'}</p>
                      </span>
                    </td>
                    <td>
                      <span className="fs-1">{dt?.age?dt.age:'NA'}</span>
                    </td>  
                    <td>
                      <span className="fs-1">{dt?.state?dt.state:'NA'}</span>
                    </td>
                    <td>
                      <span className="fs-1">{dt?.mobile?dt.mobile:'NA'}</span>
                    </td>
                    <td>
                      <span className="fs-1">{dt?.email?dt.email:'NA'}</span>
                    </td>
                    <td>
                      <span className="fs-1">{dt.isDeleted == false ? "Active":'Deleted'}</span>
                    </td>
                    <td>
                      <Link title="Edit" to={"/editpatient/"+auth?.user?.role+"/ "+auth?.user?._id+"/"+dt?._id} className="btn btn-default">
                        <i className="fa fa-edit"></i>
                      </Link>
                      {" "} 
                      {" "}
                      <Link title="View" to={"/viewpatient/"+dt?._id} className="btn btn-default">
                      <i className="fa fa-eye" />
                      </Link>
                      {" "} 
                      {" "}
                      <Link title="View Diseases" to={"/view-patient-diseases/"+dt?._id} className="btn btn-default">
                      <i className="fa fa-stethoscope" />
                      </Link>
                      {" "} 
                      {" "}
                      {/*<Link title="View Reports" to={"/view-patient-reports/"+dt?._id} className="btn btn-default">
                      <i className="fa fa-file-text" />
                      </Link>*/}
                      {" "} 
                      {" "}
                      <Link title="View Prescription" to={"/view-patient-prescription/"+dt?._id} className="btn btn-default">
                      <i className="fa fa-clipboard" />
                      </Link>
                      {" "} 
                      {" "}
                      <Link title="View BP Chart" to={"/view-patient-bp-chart/"+dt?._id} className="btn btn-default">
                      <i className="fa fa-bar-chart" />
                      </Link>
                      {" "} 
                      {" "}
                      {/* <Link title="Chat" to={"/chat/"+dt?._id} className="btn btn-default">
                      <i className="fa fa-comments" />
                      </Link> */}
                      {(() => {
                      if (
                        dt?.isDeleted == false
                      ) {
                        return (
                          <button title="Delete" className="btn btn-default" onClick={(e)=>deletePatient(dt?._id)}>
                            <i className="fa fa-trash"></i>
                          </button>
                          )
                      }
                })()}
                      
                      
                    </td>
                  </tr>
                )
              }
              })}
              {(() => {
              if (
                countryList?.length === 0
              ) {
                return (
                  <tr>
                    <td colspan="3">
                      <h6 style={{padding:"11px", textAlign:"center"}}>No Records Found</h6>
                    </td>
                  </tr>
                  )
              }
            })()}
        	  </tbody>
        	</table>
        </div>
        {/* /.box-body */}
        {/*<div className="box-footer">Footer</div>*/}
        {/* /.box-footer*/}
      </div>
      {/* /.box */}
    </section>
    {/* /.content */}
  </div>
  {/* /.content-wrapper */}
 


       <Footer/>
       <Tool/> 
   
  <div className="control-sidebar-bg" />
</div>

			
		</div>
	)
}