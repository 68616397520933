import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


export default function Sidebar() {
  const auth = useSelector(state=>state?.common?.data);
  const adminRole = useSelector(state=>state?.auth?.user?.role);

	return (
		<div>
		<aside className="main-sidebar">
    {/* sidebar: style can be found in sidebar.less */}
    <section className="sidebar">
      {/* Sidebar user panel */}
      <div className="user-panel">
        <div className="pull-left image">
          <img
            src="dist/img/admin.jpg"
            className="img-circle"
            alt="User Image"
          />
        </div>
        <div className="pull-left info">
          <p>{auth.fname} {auth.lname}</p>
          <a href="#">
            <i className="fa fa-circle text-success" /> Online
          </a>
        </div>
      </div>
      <ul className="sidebar-menu" data-widget="tree">
        <li className="header">MAIN NAVIGATION</li>

        <li>
          <Link to="/home">
            <i className="fa fa-home" /> <span>Dashboard</span>
            <span className="pull-right-container">
            </span>
          </Link>
        </li>
        <li>
          <Link to="/patients">
            <i className="fa fa-users" /> <span>Manage Patients </span>
            <span className="pull-right-container">
            </span>
          </Link>
        </li>
        <li>
          <Link to="/notifications">
            <i className="fa fa-bell" /> <span>Manage Notifications </span>
            <span className="pull-right-container">
            </span>
          </Link>
        </li>
        {(() => {
          if (
            adminRole === "Admin"
          ) {
            return (
        <li>
          <Link to="/doctors">
            <i className="fa fa-user-md" /> <span>Manage Doctors </span>
            <span className="pull-right-container">
            </span>
          </Link>
        </li>
            )
        }
      })()}

      {/* {(() => {
          if (
            adminRole === "Admin"
          ) {
            return (
         <li>
          <Link to="/diseases">
            <i className="fa fa-stethoscope" /> <span>Manage Diseases </span>
            <span className="pull-right-container">
            </span>
          </Link>
        </li>
            )
        }
      })()} */}

        <li>
          <Link to="/new-prescriptions">
            <i className="fa fa-clipboard" /> <span>Manage New Prescriptions </span>
            <span className="pull-right-container">
            </span>
          </Link>
        </li>

      {(() => {
          if (
            adminRole === "Admin"
          ) {
            return (
        <li>
          <Link to="/faqs">
            <i className="fa fa-file" /> <span>Manage CMS </span>
            <span className="pull-right-container">
            </span>
          </Link>
        </li>
            )
        }
      })()}

      
        <li>
          <Link to="/change-password">
            <i className="fa fa-lock" /> <span>Change Password </span>
            <span className="pull-right-container">
            </span>
          </Link>
        </li>
            
      </ul>
    </section>
    {/* /.sidebar */}
  </aside>
			
		</div>
	)
}