import {notificationStatus} from '../action/Status';

const initialData = {
	data:[],
};

export default (state=initialData,action)=>{
	 switch(action.type){
	 	case notificationStatus.NOTIFICATION_REQUEST:
	 	    state={
	 	    	...state
 			}
	 	break; 
	 	case notificationStatus.NOTIFICATION_GET_REQUEST:
	 	    state={
	 	    	...state
 			}
	 	break;
	 	case notificationStatus.NOTIFICATION_GET_SUCCESS:
	 	    state={
	 	    	...state,
	 	    	data:action.payload.notifications
	 	    }
	 	break;
		 case notificationStatus.NOTIFICATION_UPDATE_REQUEST:
			state={
				...state
			}
		break;
	   case notificationStatus.NOTIFICATION_UPDATE_SUCCESS:
		   state={
			   ...state
		   }
	   break;
	   case notificationStatus.NOTIFICATION_UPDATE_FAILED:
			state={
				...state
			}
		break;		 	 	            
	 }

	 return state;
}