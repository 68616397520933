import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "../../helper/axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./Chart.css";
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";
import subDays from 'date-fns/subDays';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const top = "top";
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: top,
    },
    title: {
      display: true,
      text: "BP Chart",
    },
  },
};

export function Chart() {
  const [dlabels, setDlables] = useState("");
  const [diastolicreport, setDiastolic] = useState();
  const [systolicreport, setSystolic] = useState();
  const [active, setActive] = useState("");
  const [dataToShow, setDataToShow] = useState("");
  const [tableData, setTableData] = useState("");

  const { patientId } = useParams();
  const fliterBp = async (type, event) => {
    setActive(event.target.id);
    var ndata = patientId;
    let i = 0;
    const res = await axios.post(
      `/get-graph-data?interval=${type}&patientId=` + ndata,
      ndata
    );
    let diastolic = [];
    let systolic = [];
    let nlabels = [];

    for (let data of res.data.data) {
      diastolic.push(data.diastolic);
      systolic.push(data.systolic);
      nlabels.push(data.interval);
    }

    setDlables(nlabels);
    setDiastolic(diastolic);
    setSystolic(systolic);

    const res2 = await axios.get(
      `/get-all-records?interval=${type}&patientId=` + patientId,
      patientId
    );

    if(res2?.data?.data?.data){
      setTableData(res2?.data?.data?.data);
    }
  };
  useEffect(async () => {
    var fdata = patientId;
    let i = 0;
    setActive("4");
    const newres = await axios.post(
      `/get-graph-data?interval=year&patientId=` + fdata,
      fdata
    );
    if(newres && newres.data.data.length>0){
      setDataToShow(true);
    }
    let diastolic = [];
    let systolic = [];
    let nlabels = [];

    for (let data of newres.data.data) {
      diastolic.push(data.diastolic);
      systolic.push(data.systolic);
      nlabels.push(data.interval);
    }

    setDlables(nlabels);
    setDiastolic(diastolic);
    setSystolic(systolic);

    const res2 = await axios.get(
      `/get-all-records?interval=year&patientId=` + patientId,
      patientId
    );

    if(res2?.data?.data?.data){
      setTableData(res2?.data?.data?.data);
    }

  }, []);

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const data = {
    labels: dlabels,
    datasets: [
      {
        label: "Systolic",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: systolicreport,
      },
      {
        label: "Diastolic",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        borderColor: "rgb(255, 99, 132)",
        data: diastolicreport,
      },
    ],
  };

  

  const handleSelect = async (range) => {
    const res = await axios.post(
      `/get-graph-data?interval=${range}&patientId=` + patientId,
      patientId
    );

    let diastolic = [];
    let systolic = [];
    let nlabels = [];

    for (let data of res.data.data) {
      setActive("0");
      diastolic.push(data.diastolic);
      systolic.push(data.systolic);
      nlabels.push(data.interval);
    }

    setDlables(nlabels);
    setDiastolic(diastolic);
    setSystolic(systolic);

    const res2 = await axios.get(
      `/get-all-records?interval=${range}&patientId=` + patientId,
      patientId
    );

    if(res2?.data?.data?.data){
      setTableData(res2?.data?.data?.data);
    }
 }

  return (
    <>
    <div style={{display:"flex"}}>
    <DateRangePicker
                ranges={[]} 
                hoverRange={date => [subDays(date, 1)]} 
                placeholder="Select Date Range"
                showOneCalendar
                onOk={item => handleSelect(item)}
                />
      <div style={{ marginLeft: "19%", marginTop: "0.5%" }}>
        <button
          className={
            active === "1"
              ? "active btn-dark text-white p-1 px-2 mx-5 btn fw-bold btnActive"
              : "btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
          }
          onClick={(e) => fliterBp("hour", e)}
          key={1}
          id={"1"}
        >
          Last 24hrs
        </button>
        &nbsp;&nbsp;
        <button
          className={
            active === "2"
              ? "active btn-dark text-white p-1 px-2 mx-5 btn fw-bold btnActive"
              : "btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
          }
          onClick={(e) => fliterBp("week", e)}
          key={2}
          id={"2"}
        >
          Last 7 days
        </button>
        &nbsp;&nbsp;
        <button
          className={
            active === "3"
              ? "active btn-dark text-white p-1 px-2 mx-5 btn fw-bold btnActive"
              : "btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
          }
          onClick={(e) => fliterBp("month", e)}
          key={3}
          id={"3"}
        >
          Current Month
        </button>
        &nbsp;&nbsp;
        <button
          className={
            active === "4"
              ? "active btn-dark text-white p-1 px-2 mx-5 btn fw-bold btnActive"
              : "btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
          }
          onClick={(e) => fliterBp("year", e)}
          key={4}
          id={"4"}
        >
          Current Year
        </button>
      </div>
      </div>
      <div style={{"width": "100%", marginLeft: "0%", display:"flex"}}> 
        <div style={{paddingTop: "4%"}}>
            <table title="Tabular Data" className="table table-bordered table-striped dataTable" id="example2" style={{marginTop: "61px"}}>
              <thead>
                <tr>
                <th className="fw-bold">No.</th>
                
                <th>Systolic</th>
                <th>Diastolic</th> 
                <th>Pulse</th>
                <th>Date</th>
                </tr>
              </thead>
              <tbody>
              {
              (tableData?.length>0 ? <>
                {tableData?.map((dt,index) => (
                  <>
                  <tr>
                  <td style={{width:"10px"}}>{index+1}</td>
                  <td>
                    <span className="fs-1">{dt?.systolic}</span>
                  </td>
                  <td>
                    <span className="fs-1">{dt?.diastolic}</span>
                  </td>  
                  <td>
                    <span className="fs-1">{dt?.pulse}</span>
                  </td>
                  <td>
                    <span className="fs-1">{moment(dt?.createdAt.split(',')[0]).utc().format('MM/DD/YYYY')}</span>
                  </td>

                </tr>
                </>
               
              ))}
              </>: <tr > <td colspan="5" style={{textAlign:"center"}}>No data available
                </td> </tr>)}
              </tbody>
            </table>
        </div>
        <div style={{"minWidth": "70%", marginLeft: "12%", paddingTop: "4%"}}>
        {(dataToShow ? <>
            {<Bar options={options} data={data} />} 
            </> : 
            <div style={{marginTop: "83px",marginLeft:"31%"}}>
              <b style={{fontSize: "18px"}}>No BP Records Available</b>
            </div>)}
        
        </div>
      </div>
    </>
  );
}
