import { notificationStatus } from "./Status";
import axios from "../helper/axios";
import toast from 'react-hot-toast';
const adminToken = localStorage.getItem('token');
const config = {
    headers: { Authorization: `Bearer ${adminToken}` }
};


export const GetNotificationAction=()=>{
	
	return async (dispatch)=>{
		dispatch({type:notificationStatus.NOTIFICATION_GET_REQUEST});
		const res = await axios.get(`/get-admin-notifications/`);
		console.log(res.data)
		if(res.status===200){    
			const notifications = res.data
			dispatch({
				type:notificationStatus.NOTIFICATION_GET_SUCCESS,
				payload:{ notifications }
			})

		}else{
		if(res.status===400){
			dispatch({type:notificationStatus.NOTIFICATION_GET_FAILED});
			console.log(res.data);
		}
	    }
	}
}

export const ReadNotificationAction=(data)=>{
	return async (dispatch)=>{
		dispatch({type:notificationStatus.NOTIFICATION_UPDATE_REQUEST});
		const res = await axios.post(`/read-notification/`,data);
		if(res.status === 200) {    
			const notifications= res.data
			dispatch({
				type: notificationStatus.NOTIFICATION_UPDATE_SUCCESS,
				payload:{
					notifications
				}
			})
			// toast.success('Notification updated successfully');
		} else {
		if(res.status === 201){
			dispatch({
				type: notificationStatus.NOTIFICATION_UPDATE_FAILED
			});
			toast.error(res?.data?.msg);
		}
	    }
	}
}