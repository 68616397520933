export const authStatus={
	LOGIN_REQUEST:"LOGIN_REQUEST",
	LOGIN_SUCCESS:"LOGIN_SUCCESS",
	LOGIN_FAILED:"LOGIN_FAILED",
	LOGOUT_SUCCESS:"LOGOUT_SUCCESS",
	HOMEPAGE_REACHED:"HOMEPAGE_REACHED"
}

 export const syncStatus={
 	SYNC_DESCRIPTION_REQUEST:"SYNC_DESCRIPTION_REQUEST",
 	SYNC_DESCRIPTION_SUCCESS:"SYNC_DESCRIPTION_SUCCESS",
 	SYNC_DESCRIPTION_FAILED:"SYNC_DESCRIPTION_FAILED",
 	SYNC_STOCK_REQUEST:"SYNC_STOCK_REQUEST",
 	SYNC_STOCK_SUCCESS:"SYNC_STOCK_SUCCESS",
 	SYNC_STOCK_FAILED:"SYNC_STOCK_FAILED"
 }

 export const categoryStatus={
 	CATEGORY_ADD_REQUEST:"CATEGORY_ADD_REQUEST",
 	CATEGORY_ADD_SUCCESS:"CATEGORY_ADD_SUCCESS",
 	CATEGORY_ADD_FAILED:"CATEGORY_ADD_FAILED",
 	CATEGORY_GET_REQUEST:"CATEGORY_GET_REQUEST",
 	CATEGORY_GET_SUCCESS:"CATEGORY_GET_SUCCESS",
 	CATEGORY_GET_FAILED:"CATEGORY_GET_FAILED",
 	CATEGORY_DELETE_REQUEST:"CATEGORY_DELETE_REQUEST",
 	CATEGORY_DELETE_SUCCESS:"CATEGORY_DELETE_SUCCESS",
 	CATEGORY_DELETE_FAILED:"CATEGORY_DELETE_FAILED"
 }

 export const cmsStatus={
 	CMS_REQUEST:"CMS_ADD_REQUEST",
 	CMS_SUCCESS:"CMS_ADD_SUCCESS",
 	CMS_FAILED:"CMS_ADD_FAILED",
 	CMS_GET_REQUEST:"CMS_GET_REQUEST",
 	CMS_GET_SUCCESS:"CMS_GET_SUCCESS",
 	CMS_GET_FAILED:"CMS_GET_FAILED",
 	CMS_DELETE_REQUEST:"CMS_DELETE_REQUEST",
 	CMS_DELETE_SUCCESS:"CMS_DELETE_SUCCESS",
 	CMS_DELETE_FAILED: "CMS_DELETE_FAILED",
 	CMS_UPDATE_REQUEST:"CMS_UPDATE_REQUEST",
 	CMS_UPDATE_SUCCESS:"CMS_UPDATE_SUCCESS",
 	CMS_UPDATE_FAILED: "CMS_UPDATE_FAILED",
 	COUPON_REQUEST:"COUPON_REQUEST",
 	COUPON_EXIST:"COUPON_EXIST",
 	COUPON_SUCCESS:"COUPON_SUCCESS",
 	COUPON_FAILED: "COUPON_FAILED",
 	COUPON_GET_REQUEST:"COUPON_GET_REQUEST",
 	COUPON_GET_SUCCESS:"COUPON_GET_SUCCESS",
 	COUPON_GET_FAILED:"COUPON_GET_FAILED",
 	COUPON_UPDATE_REQUEST:"COUPON_UPDATE_REQUEST",
	COUPON_UPDATE_SUCCESS:"COUPON_UPDATE_SUCCESS",
	COUPON_UPDATE_FAILED:"COUPON_UPDATE_FAILED",
 	COUPON_DELETE_REQUEST:"COUPON_DELETE_REQUEST",
	COUPON_DELETE_SUCCESS:"COUPON_DELETE_SUCCESS",
	COUPON_DELETE_FAILED:"COUPON_DELETE_FAILED"
 }

 export const vitalStatus={
	VITAL_GET_SUCCESS:"VITAL_GET_SUCCESS",
	VITAL_GET_FAILED:"VITAL_GET_FAILED",
	VITAL_GET_REQUEST:"VITAL_GET_REQUEST",
	VITAL_REQUEST:"VITAL_REQUEST",
	VITAL_EXIST:"VITAL_EXIST",
	VITAL_SUCCESS:"VITAL_SUCCESS",
	VITAL_FAILED: "VITAL_FAILED",
	VITAL_DELETE_REQUEST:"VITAL_DELETE_REQUEST",
 	VITAL_DELETE_SUCCESS:"VITAL_DELETE_SUCCESS",
 	VITAL_DELETE_FAILED: "VITAL_DELETE_FAILED",
 	VITAL_UPDATE_REQUEST:"VITAL_UPDATE_REQUEST",
 	VITAL_UPDATE_SUCCESS:"VITAL_UPDATE_SUCCESS",
 	VITAL_UPDATE_FAILED: "VITAL_UPDATE_FAILED",
}

export const diseaseStatus={
	DISEASE_GET_SUCCESS:"DISEASE_GET_SUCCESS",
	DISEASE_GET_FAILED:"DISEASE_GET_FAILED",
	DISEASE_GET_REQUEST:"DISEASE_GET_REQUEST",
	DISEASE_REQUEST:"DISEASE_REQUEST",
	DISEASE_EXIST:"DISEASE_EXIST",
	DISEASE_SUCCESS:"DISEASE_SUCCESS",
	DISEASE_FAILED: "DISEASE_FAILED",
	DISEASE_DELETE_REQUEST:"DISEASE_DELETE_REQUEST",
 	DISEASE_DELETE_SUCCESS:"DISEASE_DELETE_SUCCESS",
 	DISEASE_DELETE_FAILED: "DISEASE_DELETE_FAILED",
 	DISEASE_UPDATE_REQUEST:"DISEASE_UPDATE_REQUEST",
 	DISEASE_UPDATE_SUCCESS:"DISEASE_UPDATE_SUCCESS",
 	DISEASE_UPDATE_FAILED: "DISEASE_UPDATE_FAILED",
}

export const medicineStatus={
	MEDICINE_GET_SUCCESS:"MEDICINE_GET_SUCCESS",
	MEDICINE_GET_FAILED:"MEDICINE_GET_FAILED",
	MEDICINE_GET_REQUEST:"MEDICINE_GET_REQUEST",
	MEDICINE_REQUEST:"MEDICINE_REQUEST",
	MEDICINE_EXIST:"MEDICINE_EXIST",
	MEDICINE_SUCCESS:"MEDICINE_SUCCESS",
	MEDICINE_FAILED: "MEDICINE_FAILED",
	MEDICINE_DELETE_REQUEST:"MEDICINE_DELETE_REQUEST",
 	MEDICINE_DELETE_SUCCESS:"MEDICINE_DELETE_SUCCESS",
 	MEDICINE_DELETE_FAILED: "MEDICINE_DELETE_FAILED",
 	MEDICINE_UPDATE_REQUEST:"MEDICINE_UPDATE_REQUEST",
 	MEDICINE_UPDATE_SUCCESS:"MEDICINE_UPDATE_SUCCESS",
 	MEDICINE_UPDATE_FAILED: "MEDICINE_UPDATE_FAILED",
}

export const adminStatus = {
	ADMIN_PROFILE_UPDATE_REQUEST:"ADMIN_PROFILE_UPDATE_REQUEST",
 	ADMIN_PROFILE_UPDATE_SUCCESS:"ADMIN_PROFILE_UPDATE_SUCCESS",
 	ADMIN_PROFILE_UPDATE_FAILED: "ADMIN_PROFILE_UPDATE_FAILED",
	ADMIN_PROFILE_GET_SUCCESS:"ADMIN_PROFILE_GET_SUCCESS",
	ADMIN_PROFILE_GET_FAILED:"ADMIN_PROFILE_GET_FAILED",
	ADMIN_PROFILE_GET_REQUEST:"ADMIN_PROFILE_GET_REQUEST",
}

export const prescriptionStatus={
	PRESCRIPTION_GET_SUCCESS:"PRESCRIPTION_GET_SUCCESS",
	NEW_PRESCRIPTION_GET_SUCCESS:"NEW PRESCRIPTION_GET_SUCCESS",
	PRESCRIPTION_GET_FAILED:"PRESCRIPTION_GET_FAILED",
	PRESCRIPTION_GET_REQUEST:"PRESCRIPTION_GET_REQUEST",
	PRESCRIPTION_REQUEST:"PRESCRIPTION_REQUEST",
	PRESCRIPTION_EXIST:"PRESCRIPTION_EXIST",
	PRESCRIPTION_SUCCESS:"PRESCRIPTION_SUCCESS",
	PRESCRIPTION_FAILED: "PRESCRIPTION_FAILED",
	PRESCRIPTION_DELETE_REQUEST:"PRESCRIPTION_DELETE_REQUEST",
 	PRESCRIPTION_DELETE_SUCCESS:"PRESCRIPTION_DELETE_SUCCESS",
 	PRESCRIPTION_DELETE_FAILED: "PRESCRIPTION_DELETE_FAILED",
 	NEW_PRESCRIPTION_UPDATE_REQUEST:"NEW_PRESCRIPTION_UPDATE_REQUEST",
 	NEW_PRESCRIPTION_UPDATE_SUCCESS:"NEW_PRESCRIPTION_UPDATE_SUCCESS",
 	NEW_PRESCRIPTION_UPDATE_FAILED: "NEW_PRESCRIPTION_UPDATE_FAILED",
	PRESCRIPTION_UPDATE_REQUEST:"PRESCRIPTION_UPDATE_REQUEST",
 	PRESCRIPTION_UPDATE_SUCCESS:"PRESCRIPTION_UPDATE_SUCCESS",
 	PRESCRIPTION_UPDATE_FAILED: "PRESCRIPTION_UPDATE_FAILED",
}

export const BPStatus={
	BP_GET_SUCCESS:"BP_GET_SUCCESS",
	BP_GET_FAILED:"BP_GET_FAILED",
	BP_GET_REQUEST:"BP_GET_REQUEST",
	BP_REQUEST:"BP_REQUEST",
	BP_EXIST:"BP_EXIST",
	BP_SUCCESS:"BP_SUCCESS",
	BP_FAILED: "BP_FAILED",
	BP_DELETE_REQUEST:"BP_DELETE_REQUEST",
 	BP_DELETE_SUCCESS:"BP_DELETE_SUCCESS",
 	BP_DELETE_FAILED: "BP_DELETE_FAILED",
 	BP_UPDATE_REQUEST:"BP_UPDATE_REQUEST",
 	BP_UPDATE_SUCCESS:"BP_UPDATE_SUCCESS",
 	BP_UPDATE_FAILED: "BP_UPDATE_FAILED",
}

export const faqStatus={
	FAQ_GET_SUCCESS:"FAQ_GET_SUCCESS",
	FAQ_GET_FAILED:"FAQ_GET_FAILED",
	FAQ_GET_REQUEST:"FAQ_GET_REQUEST",
	FAQ_REQUEST:"FAQ_REQUEST",
	FAQ_EXIST:"FAQ_EXIST",
	FAQ_SUCCESS:"FAQ_SUCCESS",
	FAQ_FAILED: "FAQ_FAILED",
	FAQ_DELETE_REQUEST:"FAQ_DELETE_REQUEST",
 	FAQ_DELETE_SUCCESS:"FAQ_DELETE_SUCCESS",
 	FAQ_DELETE_FAILED: "FAQ_DELETE_FAILED",
 	FAQ_UPDATE_REQUEST:"FAQ_UPDATE_REQUEST",
 	FAQ_UPDATE_SUCCESS:"FAQ_UPDATE_SUCCESS",
 	FAQ_UPDATE_FAILED: "FAQ_UPDATE_FAILED",
}

export const patientStatus={
	PATIENT_GET_SUCCESS:"PATIENT_GET_SUCCESS",
	PATIENT_GET_FAILED:"PATIENT_GET_FAILED",
	PATIENT_GET_REQUEST:"PATIENT_GET_REQUEST",
	PATIENT_REQUEST:"PATIENT_REQUEST",
	PATIENT_EXIST:"PATIENT_EXIST",
	PATIENT_SUCCESS:"PATIENT_SUCCESS",
	PATIENT_FAILED: "PATIENT_FAILED",
	PATIENT_DELETE_REQUEST:"PATIENT_DELETE_REQUEST",
 	PATIENT_DELETE_SUCCESS:"PATIENT_DELETE_SUCCESS",
 	PATIENT_DELETE_FAILED: "PATIENT_DELETE_FAILED",
 	PATIENT_UPDATE_REQUEST:"PATIENT_UPDATE_REQUEST",
 	PATIENT_UPDATE_SUCCESS:"PATIENT_UPDATE_SUCCESS",
 	PATIENT_UPDATE_FAILED: "PATIENT_UPDATE_FAILED",
}

export const doctorStatus={
	DOCTOR_GET_SUCCESS:"DOCTOR_GET_SUCCESS",
	DOCTOR_GET_FAILED:"DOCTOR_GET_FAILED",
	DOCTOR_GET_REQUEST:"DOCTOR_GET_REQUEST",
	DOCTOR_REQUEST:"DOCTOR_REQUEST",
	DOCTOR_EXIST:"DOCTOR_EXIST",
	DOCTOR_SUCCESS:"DOCTOR_SUCCESS",
	DOCTOR_FAILED: "DOCTOR_FAILED",
	DOCTOR_DELETE_REQUEST:"DOCTOR_DELETE_REQUEST",
 	DOCTOR_DELETE_SUCCESS:"DOCTOR_DELETE_SUCCESS",
 	DOCTOR_DELETE_FAILED: "DOCTOR_DELETE_FAILED",
 	DOCTOR_UPDATE_REQUEST:"DOCTOR_UPDATE_REQUEST",
 	DOCTOR_UPDATE_SUCCESS:"DOCTOR_UPDATE_SUCCESS",
 	DOCTOR_UPDATE_FAILED: "DOCTOR_UPDATE_FAILED",
}

export const notificationStatus={
	NOTIFICATION_GET_SUCCESS:"NOTIFICATION_GET_SUCCESS",
	NOTIFICATION_GET_FAILED:"NOTIFICATION_GET_FAILED",
	NOTIFICATION_GET_REQUEST:"NOTIFICATION_GET_REQUEST",
	NOTIFICATION_REQUEST:"NOTIFICATION_REQUEST",
	NOTIFICATION_EXIST:"NOTIFICATION_EXIST",
	NOTIFICATION_SUCCESS:"NOTIFICATION_SUCCESS",
	NOTIFICATION_FAILED: "NOTIFICATION_FAILED",
	NOTIFICATION_UPDATE_REQUEST:"NOTIFICATION_UPDATE_REQUEST",
 	NOTIFICATION_UPDATE_SUCCESS:"NOTIFICATION_UPDATE_SUCCESS",
 	NOTIFICATION_UPDATE_FAILED: "NOTIFICATION_UPDATE_FAILED",
}