import {authStatus, adminStatus} from '../action/Status';

const initialData = {
	token:null,
	user:[],
	authenticate:false,
	authenticating:false, 
	loading:false,
	error:null,
	message:"",
	automaticRedirection: false
};

export default (state=initialData,action)=>{
	 console.log(action)
	 switch(action.type){
	 	case authStatus.LOGIN_REQUEST:
	 		state={
	 		    ...state,
	 		    authenticating:true
	 		}
	 	break; 
	 	case authStatus.LOGIN_SUCCESS:
	 	    state={
	 	    	...state,
	 	    	user:action.payload.user,
	 	    	token:action.payload.token,
	 	    	authenticate:true,
	 	        authenticating:false,
				automaticRedirection:true
	 	    }
	 	break;
		case authStatus.HOMEPAGE_REACHED:
			state={
				...state,
			   automaticRedirection:false
			}
		break;
		case authStatus.LOGIN_FAILED:
			state={
				user:[],
				token:null,
				authenticate:false,
				authenticating:false

			}
		break;
		// case authStatus.LOGOUT_SUCCESS:
		// 	state = null;
		// break;
	 	// case regStatus.REG_REQUEST:
	 	//     state={
	 	//     	...state,
	 	//     	authenticating:true,
	 	//     }
	 	// break;
	 	// case regStatus.REG_SUCCESS:
	 	//      state={
	 	//      	...state, 
	 	//      	authenticating:true,
	 	//      	authenticate:false,
	 	//      	token:action.payload.token,
	 	//      	user:action.payload.user

	 	//      }
	 	// break;

	 		 	            
	 }

	 return state;
}