import React, {useEffect,useState} from 'react'
import {Link,useParams, useNavigate} from "react-router-dom";
import Header from '../../../header/Header';
import Sidebarr from '../../../sidebar/Sidebar';
import Tool from '../../../sidebar/Tool';
import Footer from '../../../footer/Footer';
import {useDispatch,useSelector} from "react-redux";
import { useForm } from "react-hook-form";
import { Toaster } from 'react-hot-toast';
import { ReadNotificationAction } from "../../../action";
import axios from "../../../helper/axios";
  
export default function EditPatient() {
  const { id } = useParams();
  const { formState: { errors } } = useForm();
  const dispatch =  useDispatch()
  const obj = {}
  const [inputField , setInputField] = useState(obj)

const inputsHandler = (e) =>{
    let name = e.target.name; 
    let value = e.target.value;
    inputField[name] = value;
    setInputField(inputField);
}

 const notificationData = useSelector(state=>state?.notification?.data?.data)
 const data = notificationData.find(obj=>{return obj._id === id});
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [title,setTitle] = useState("");
  const [body,setBody] = useState("");
  const [description,setDescription] = useState("");
  const [medicine,setMedicine] = useState("");
  const [patientId,setPatientId] = useState("");
  const [patientName,setPatientName] = useState("");
  const [prescriptionId,setPrescriptionId] = useState("");
  const [viewComment,setViewComment] = useState("");

  const [field,setField] = useState({
    comment:''
  });

  const handleChange= (event)=>{
    setField({...field, [event.target.name]: event.target.value})
  }
  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(async() => {
    setEditorLoaded(true);
    setTitle(data?.title) 
    setBody(data?.body) 
    setDescription(data?.description)
    setMedicine(data?.medicine) 
    setPatientId(data?.patientId?._id)
    setPatientName(data?.patientId?.name)
    setPrescriptionId(data?.prescriptionId)
    setViewComment(data?.comment)
    if(id){
      await dispatch(ReadNotificationAction({notificationId:id}))
    }
  }, []); 

const handleAddComment = async (e)=>{
  e.preventDefault();

  const formData = {
    comment:field.comment,
    notificationId:id

  }

  const resData = axios.post('/add-comment/',formData).then(result=>{

    if(result?.data?.status){
      window.location.href="/notifications";
    }
    console.log("REsponse:",result)
  }).catch(error=>{
    console.log("Error==",error)
  })
}  

	return (
		<div className="sidebar-mini skin-green-light">
      <div><Toaster/></div>
		<div className="wrapper"> 
  		  <Header/> 
  		  <Sidebarr/> 
  <div className="content-wrapper"> 
    <section className="content-header">
      <h1>
       View Notification
      </h1>
      <ol className="breadcrumb">
        <li>
          <a href="/home">
            <i className="fa fa-dashboard" /> Home
          </a>
        </li>
        <li>
          <a href="/notifications">Manage Notification</a>
        </li>
        <li style={{fontWeight:"bold"}}>View Notification</li>
      </ol>
    </section> 
    <section className="content"> 
      <div className="box">
      <div className="box-header with-border"> 
          <div className="box-tools pull-right">
            <Link
              to="/notifications"
              type="button"
              className="btn btn-success" 
              data-toggle="tooltip"
              title="Back"
            >
              {"Go Back"}
            </Link> 
          </div>
        </div>

        <div className="box-body">
        <section className="content">
          <div className="row">  
            <div className="col-md-12">
              <div className="nav-tabs-custom">
                <div className="tab-content">
                <div className="active tab-pane" id="settings">
                    <form className="form-horizontal">
                      <div className="form-group">
                        <label htmlFor="title" className="col-sm-2 control-label">Title</label>
                        <div className="col-sm-8">
                          <input type="text"  className="form-control viewDetail" id="title" placeholder="" name="title" value={title} onChange={(e)=>setTitle(e.target.value)} readOnly/>
                          {errors.title && <span className='validationError'>Required</span>}
                        </div> 
                      </div>

                       <div className="form-group">
                        <label htmlFor="body" className="col-sm-2 control-label">Description</label>
                        <div className="col-sm-8">
                          <input type="text"  className="form-control viewDetail" id="body" placeholder="" name="body" value={body} onChange={(e)=>setBody(e.target.value)} readOnly/>
                          {errors.body && <span className='validationError'>Required</span>}
                        </div> 
                      </div>
                      {(() => {
                      if (
                        medicine.length > 0
                      ) {
                        return (
                          <div className="form-group">
                                    <label htmlFor="medicine" className="col-sm-2 control-label">Side-Effect Medicines</label>
                                    <div className="col-sm-8">
                                      <input type="text"  className="form-control viewDetail" id="medicine" placeholder="" name="medicine" value={medicine} onChange={(e)=>setBody(e.target.value)} readOnly/>
                                      {errors.medicine && <span className='validationError'>Required</span>}
                                    </div> 
                                  </div>
                          )
                        
                        }
                      })()}

                      {(() => {
                      if (
                        description && description !== ""
                      ) {
                        return (
                          <div className="form-group">
                                    <label htmlFor="description" className="col-sm-2 control-label">Patient's Issue</label>
                                    <div className="col-sm-8">
                                      <input type="text"  className="form-control viewDetail" id="description" placeholder="" name="description" value={description} onChange={(e)=>setDescription(e.target.value)} readOnly/>
                                      {errors.description && <span className='validationError'>Required</span>}
                                    </div> 
                                  </div>
                          )
                        
                        }
                      })()}
                      {(() => {
                        if (
                          patientName && patientName !== ""
                        ) {
                          return (
                            <div className="form-group">
                                      <label htmlFor="patientName" className="col-sm-2 control-label">Patient's Name</label>
                                      <div className="col-sm-8">
                                        <input type="text"  className="form-control viewDetail" id="patientName" placeholder="" name="patientName" value={patientName} onChange={(e)=>setPatientName(e.target.value)} readOnly/>
                                        {errors.patientName && <span className='validationError'>Required</span>}
                                      </div> 
                                    </div>
                            )
                          
                          }
                      })()}
                      <div className="form-group">
                        <label htmlFor="patientId" className="col-sm-2 control-label">Patient's ID</label>
                        <div className="col-sm-8">
                          <input type="text"  className="form-control viewDetail" id="patientId" placeholder="" name="patientId" value={patientId} onChange={(e)=>setPatientId(e.target.value)} readOnly/>
                          {errors.patientId && <span className='validationError'>Required</span>}
                        </div> 
                      </div>

                      <div className="form-group">
                        <label htmlFor="patientId" className="col-sm-2 control-label">Last Comment</label>
                        <div className="col-sm-8">
                          <input type="text"  className="form-control viewDetail" id="viewComment" placeholder="" name="viewComment" value={viewComment} onChange={(e)=>setPatientId(e.target.value)} readOnly/>
                          {errors.patientId && <span className='validationError'>Required</span>}
                        </div> 
                      </div>

                      <div className="form-group">
                        <label htmlFor="patientId" className="col-sm-2 control-label">Add New Comment</label>
                        <div className="col-sm-8">
                          <input type="text"  className="form-control viewDetail" id="comment" placeholder="" name="comment"
                           value={field.comment}
                           onChange={handleChange}/>
                        </div> 
                      </div>
                      

                      <div class="form-group row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3">
                          <button type="button" className="btn btn-danger" onClick={handleAddComment}>Add Comment</button>
                        </div>
                        <div className="col-sm-3">
                          <Link to={"/viewpatient/"+patientId} className="btn btn-default" style={{ background:"transparent", border:"transparent"}}>
                              <button type="button" className="btn btn-danger" >Go To Patient</button>
                          </Link>
                        </div>
                        <div className="col-sm-3">
                          <Link to={"/view-patient-prescription/"+patientId} className="btn btn-default" style={{ background:"transparent", border:"transparent"}}>
                              <button type="button" className="btn btn-danger" >Go To Prescription</button>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div> 
                </div> 
              </div> 
            </div> 
          </div> 
        </section> 
        </div>
       </div> 
    </section> 
  </div> 
  <Footer/>
       <Tool/> 
   
  <div className="control-sidebar-bg" />
</div>

			
		</div>
	)
}