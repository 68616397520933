import React from 'react'

export default function Footer() {
	return (
		<div>
		 <footer className="main-footer">
    <div className="pull-right hidden-xs">
      <b>Version</b> 1.0
    </div>
    <strong>
      Copyright © 2023 <a href="/home">BP Treat</a>.
    </strong>{" "}
    All rights reserved.
  </footer>
			
		</div>
	)
}