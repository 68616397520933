import TawkTo from 'tawkto-react';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../../header/Header";
import Sidebarr from "../../../sidebar/Sidebar";
import Tool from "../../../sidebar/Tool";
import Footer from "../../../footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";

export default function EditPatient() {
  const { patientId } = useParams();
  const {
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state);
  const obj = {};
  const [inputField, setInputField] = useState(obj);

  const inputsHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    inputField[name] = value;
    setInputField(inputField);
  };
  const [editorLoaded, setEditorLoaded] = useState(false);

  const patientData = useSelector((state) => state?.patient?.data);
  
  useEffect(async() => {
    setEditorLoaded(true);
    const data = patientData.find((obj) => {
      return obj._id === patientId;
    });

    // var tawk = new TawkTo("634ff461b0d6371309ca5d36", "1gfo55823")

    // tawk.onStatusChange((status) => 
    // {
    //     // console.log(status)
    // })
  }, []);

  return (
    <div className="sidebar-mini skin-green-light">
      <div>
        <Toaster />
      </div>
      <div className="wrapper">
        <Header />
        <Sidebarr />
        <div className="content-wrapper">
          <section className="content-header">
            <h1>View Patient Diseases</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/home">
                  <i className="fa fa-dashboard" /> Home
                </a>
              </li>
              <li>
                <a href="/patients">Manage patient</a>
              </li>
              <li style={{fontWeight:"bold"}}>View patient diseases</li>
            </ol>
          </section>
          <section className="content">
            <div className="box">
              <div className="box-header with-border">
                <div className="box-tools pull-right">
                  <Link
                    to="/patients"
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    title="Back"
                  >
                    {"Go Back"}
                  </Link>
                </div>
              </div>

              <div className="box-body">
                <section className="content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="nav-tabs-custom">
                        <div className="tab-content">
                          <div className="active tab-pane" id="settings">
                            <form className="form-horizontal">
                              <div className="form-group">
                                
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <Tool />

        <div className="control-sidebar-bg" />
      </div>
    </div>
  );
}
