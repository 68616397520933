import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom'
import {useDispatch,useSelector} from "react-redux";
import Dashboard from './view/Dashboard';
import Login from './view/Login';
import Reg from './view/Reg';
import AdminRouter from "./privateRoute/PrivateRoute";

import PatientList from './component/admin/Patient/PatientList';
import AddPatient from './component/admin/Patient/AddPatient';
import EditPatient from './component/admin/Patient/EditPatient';
import ViewPatient from './component/admin/Patient/ViewPatient';
import ViewPatientDiseases from './component/admin/Patient/ViewDiseases';
import ViewPatientReports from './component/admin/Patient/ViewPatientReport';
import ViewPatientPrescription from './component/admin/Patient/ViewPatientPrescription';
import AddPrescription from './component/admin/Patient/AddPrescription';
import EditPrescription from './component/admin/Patient/EditPrescription';
import PatientChat from './component/admin/Patient/PatientChat';
import PatientBPChart from './component/admin/Patient/PatientBPChart';

import ManageVitalList from './component/admin/ManageVitalList';
import AddVital from './component/admin/AddVital';
import EditVital from './component/admin/EditVital';

import ManageDiseases from './component/admin/Diseases/ManageDiseases';
import AddDisease from './component/admin/Diseases/AddDisease';
import EditDisease from './component/admin/Diseases/EditDisease';

import ManageMedicine from './component/admin/Medicine/ManageMedicine';
import AddMedicine from './component/admin/Medicine/AddMedicine';
import EditMedicine from './component/admin/Medicine/EditMedicine';

import ManageFaq from './component/admin/Faq/ManageFaq';
import AddFaq from './component/admin/Faq/AddFaq';
import EditFaq from './component/admin/Faq/EditFaq';

import ManageDoctor from './component/admin/Doctor/ManageDoctor';
import AddDoctor from './component/admin/Doctor/AddDoctor';
import EditDoctor from './component/admin/Doctor/EditDoctor';

import ManageNotification from './component/admin/Notification/ManageNotification';
import ViewNotification from './component/admin/Notification/ViewNotification';

import ManageAutomaticPrescription from './component/admin/AutomaticPescription/ManageAutomaticPrescription';

import Profile from './component/admin/Profile';
import ChangePassword from './component/admin/ChangePassword';
import PrivacyPolicy from './view/PrivacyPolicy';
import DeletePatientLogin from './component/admin/Patient/DeletePatientLogin';
import DeletePatient from './component/admin/Patient/DeletePatient';

export default function App() {
  
  const auth = useSelector(state=>state.auth);
  //console.log('authlogin',auth)
    const navigate = useNavigate();
    useEffect(() => {
     if(auth?.authenticate && auth?.automaticRedirection){
     navigate("/home");
    } 
   }, [auth])
  return (
    <div>
       <Routes>
         <Route path="/" element={<Login/>}/>
         <Route path="/reg" element={<Reg/>}/>
         <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
         <Route path="/home" element={<AdminRouter redirectTo="/"><Dashboard/></AdminRouter>}/>
         <Route path="/profile" element={<AdminRouter redirectTo="/"><Profile/></AdminRouter>}/>
         <Route path="/change-password" element={<AdminRouter redirectTo="/"><ChangePassword/></AdminRouter>}/>

         <Route path="/patients" element={<AdminRouter redirectTo="/"><PatientList/></AdminRouter>}/>
         <Route path="/addpatient" element={<AdminRouter redirectTo="/"><AddPatient/></AdminRouter>}/>
         <Route path="/editpatient/:role/:userId/:patientId" element={<AdminRouter redirectTo="/"><EditPatient/></AdminRouter>}/>
         <Route path="/viewpatient/:patientId" element={<AdminRouter redirectTo="/"><ViewPatient/></AdminRouter>}/>
         <Route path="/view-patient-diseases/:patientId" element={<AdminRouter redirectTo="/"><ViewPatientDiseases/></AdminRouter>}/>
         <Route path="/view-patient-reports/:patientId" element={<AdminRouter redirectTo="/"><ViewPatientReports/></AdminRouter>}/>
         <Route path="/view-patient-prescription/:patientId" element={<AdminRouter redirectTo="/"><ViewPatientPrescription/></AdminRouter>}/>
         <Route path="/addprescription/:patientId" element={<AdminRouter redirectTo="/"><AddPrescription/></AdminRouter>}/>
         <Route path="/editprescription/:prescriptionId" element={<AdminRouter redirectTo="/"><EditPrescription/></AdminRouter>}/>
         <Route path="/chat/:patientId" element={<AdminRouter redirectTo="/"><PatientChat/></AdminRouter>}/>
         <Route path="/view-patient-bp-chart/:patientId" element={<AdminRouter redirectTo="/"><PatientBPChart/></AdminRouter>}/>


         <Route path="/vitals" element={<AdminRouter redirectTo="/"><ManageVitalList/></AdminRouter>}/>
         <Route path="/addvital" element={<AdminRouter redirectTo="/"><AddVital/></AdminRouter>}/>
         <Route path="/editvital/:id" element={<AdminRouter redirectTo="/"><EditVital/></AdminRouter>}/>

         <Route path="/users" element={<AdminRouter redirectTo="/"><PatientList/></AdminRouter>}/>

         <Route path="/diseases" element={<AdminRouter redirectTo="/"><ManageDiseases/></AdminRouter>}/>
         <Route path="/add-disease" element={<AdminRouter redirectTo="/"><AddDisease/></AdminRouter>}/>
         <Route path="/edit-disease/:id" element={<AdminRouter redirectTo="/"><EditDisease/></AdminRouter>}/>

         <Route path="/medicines" element={<AdminRouter redirectTo="/"><ManageMedicine/></AdminRouter>}/>
         <Route path="/add-medicine" element={<AdminRouter redirectTo="/"><AddMedicine/></AdminRouter>}/>
         <Route path="/edit-medicine/:id" element={<AdminRouter redirectTo="/"><EditMedicine/></AdminRouter>}/>

         <Route path="/faqs" element={<AdminRouter redirectTo="/"><ManageFaq/></AdminRouter>}/>
         <Route path="/add-faq" element={<AdminRouter redirectTo="/"><AddFaq/></AdminRouter>}/>
         <Route path="/edit-faq/:id" element={<AdminRouter redirectTo="/"><EditFaq/></AdminRouter>}/>

         <Route path="/doctors" element={<AdminRouter redirectTo="/"><ManageDoctor/></AdminRouter>}/>
         <Route path="/add-doctor" element={<AdminRouter redirectTo="/"><AddDoctor/></AdminRouter>}/>
         <Route path="/edit-doctor/:id" element={<AdminRouter redirectTo="/"><EditDoctor/></AdminRouter>}/>

         <Route path="/notifications" element={<AdminRouter redirectTo="/"><ManageNotification/></AdminRouter>}/>
         <Route path="/view-notification/:id" element={<AdminRouter redirectTo="/"><ViewNotification/></AdminRouter>}/>
       
         <Route path="/new-prescriptions" element={<AdminRouter redirectTo="/"><ManageAutomaticPrescription/></AdminRouter>}/>
         <Route path="/delete-patient-login" element={<DeletePatientLogin />} /> 
         <Route path="/delete-patient" element={<DeletePatient />} />

       </Routes>
   </div>
  )
}

 