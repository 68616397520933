import React, {useEffect,useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import Header from '../../../header/Header';
import Sidebarr from '../../../sidebar/Sidebar';
import Tool from '../../../sidebar/Tool';
import Footer from '../../../footer/Footer';
import {AddDoctorAction} from "../../../action";
import {useDispatch,useSelector} from "react-redux";
import { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";

  
export default function AddDoctor() {
  const dispatch =  useDispatch()
  const obj = {}
  const { formState: { errors } } = useForm();
  const [inputField , setInputField] = useState(obj);
//   const genderData = [
//     { value: 'Male', name: 'male' },
//     { value: 'Female', name: 'female' },
//     { value: 'Other', name: 'other' }
//   ]

const inputsHandler = (e) =>{
    let name = e.target.name; 
    let value = e.target.value;
    inputField[name] = value;
    setInputField(inputField);
}

const [editorLoaded, setEditorLoaded] = useState(false); 
const [fname,setFname] = useState("");
const [lname,setLname] = useState("");
// const [designation,setDesignation] = useState("");
// const [specialization,setSpecialization] = useState("");
const [address,setAddress] = useState("");
const [contact,setContact] = useState("");
const [email,setEmail] = useState("");
const [state,setState] = useState("");
const [zipcode,setZipcode] = useState("");
// const [gender,setGender] = useState("");
const [password,setPassword] = useState("");

const onSubmit = () => { 
   const data={
    fname,
    lname,
    // designation,
    // specialization,
    address,
    contact,
    email,
    state,
    zipcode,
    // gender,
    password
  }
   dispatch(AddDoctorAction(data));
   setFname("");
   setLname("");
//    setDesignation("");
   setContact("");
   setEmail("");
   setState("");
//    setGender("");
   setPassword("");
   setZipcode("");
   setAddress("");
//    setSpecialization("");
}

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

	return (
    <div className="sidebar-mini skin-green-light">
        <div><Toaster/></div>
    <div className="wrapper"> 
  		  <Header/> 
  		  <Sidebarr/> 
    <div className="content-wrapper"> 
    <section className="content-header">
      <h1>
       Add New Doctor
      </h1>
      <ol className="breadcrumb">
        <li>
        <Link to="/home" className="fa fa-dashboard"> Home</Link>
        </li>
        <li>
        <Link to="/doctors" className="small-box-footer">Manage Doctors</Link>
        </li>
        <li style={{fontWeight:"bold"}}>Add Doctor</li>
      </ol>
    </section> 
    <section className="content"> 
        <div className="box">
            <div className="box-header with-border"> 
                <div className="box-tools pull-right">
                    <Link
                    to="/doctors"
                    type="button"
                    className="btn btn-success" 
                    data-toggle="tooltip"
                    title="Add"
                    >
                    <i className="fa fa-eye" />
                    {" View Doctor"}
                    </Link> 
                </div>
            </div>

            <div className="box-body">
            <section className="content">
            <div className="row">  
                <div className="col-md-12">
                <div className="nav-tabs-custom">
                    <div className="tab-content">
                    <div className="active tab-pane" id="settings">
                        <form className="form-horizontal">
                        <div className="form-group">
                            <label htmlFor="fname" className="col-sm-2 control-label">Name</label>
                            <div className="col-sm-4">
                            <input type="text" className="form-control" id="fname" placeholder="First Name" name="fname" value={fname} onChange={(e)=>setFname(e.target.value)}/>
                            {errors.fname && <span className='validationError'>Required</span>}
                            </div> 
                            <div className="col-sm-4">
                            <input type="text" className="form-control" id="lname" placeholder="Last Name" name="lname" value={lname} onChange={(e)=>setLname(e.target.value)}/>
                            {errors.lname && <span className='validationError'>Required</span>}
                            </div> 
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="designation" className="col-sm-2 control-label">Designation</label>
                            <div className="col-sm-8">
                            <input type="text" className="form-control" id="designation" placeholder="" name="designation" value={designation} onChange={(e)=>setDesignation(e.target.value)}/>
                            {errors.designation && <span className='validationError'>Required</span>}
                            </div> 
                        </div>

                        <div className="form-group">
                            <label htmlFor="specialization" className="col-sm-2 control-label">Specialization</label>
                            <div className="col-sm-8">
                            <input type="text" className="form-control" id="specialization" placeholder="" name="specialization" value={specialization} onChange={(e)=>setSpecialization(e.target.value)}/>
                            </div> 
                        </div>*/}

                        <div className="form-group">
                            <label htmlFor="address" className="col-sm-2 control-label">Address</label>
                            <div className="col-sm-8">
                            <input type="text" className="form-control" id="address" placeholder="" name="address" value={address} onChange={(e)=>setAddress(e.target.value)}/>
                            {errors.address && <span className='validationError'>Required</span>}
                            </div> 
                        </div> 
    
                        <div className="form-group">
                            <label htmlFor="contact" className="col-sm-2 control-label">Contact</label>
                            <div className="col-sm-8">
                            <input type="text" value={contact} className="form-control" id="contact" placeholder="" name="contact" onChange={(e)=>setContact(e.target.value)}/>
                            {errors.contact && <span className='validationError'>Required</span>}
                            </div>
                        </div> 

                        <div className="form-group">
                            <label htmlFor="email" className="col-sm-2 control-label">Email</label>
                            <div className="col-sm-8"> 
                            <input type="text" value={email} className="form-control" id="email" placeholder="" name="email" onChange={(e)=>setEmail(e.target.value)}/>
                            </div> 
                        </div> 
                        
                        <div className="form-group">
                            <label htmlFor="state" className="col-sm-2 control-label">Address</label>
                            <div className="col-sm-4"> 
                            <input type="text" value={state} className="form-control" id="state" placeholder="State" name="state" onChange={(e)=>setState(e.target.value)}/>
                            </div> 
                            <div className="col-sm-4"> 
                            <input type="text" value={zipcode} className="form-control" id="zipcode" placeholder="Zipcode" name="zipcode" onChange={(e)=>setZipcode(e.target.value)}/>
                            </div> 
                        </div>

                        <div className="form-group">
                            <label htmlFor="password" className="col-sm-2 control-label">Password</label>
                            <div className="col-sm-8"> 
                            <input type="password" value={password} className="form-control" id="password" placeholder="" name="password" onChange={(e)=>setPassword(e.target.value)}/>
                            </div> 
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="gender" className="col-sm-2 control-label">Gender</label>
                            <div className="col-sm-8"> 
                                <select
                                class="form-control"
                                required
                                id="gender"
                                onChange={(e)=>setGender(e.target.value)}>
                                    <option value=''>---- Choose one option ----</option>
                                {genderData.map(({ value, name }, index) => <option value={name} >{value}</option>)}
                                </select>                            
                            </div> 
                        </div> */}

                        <div className="form-group">
                            <div className="col-sm-offset-2 col-sm-8">
                            <button type="button" className="btn btn-danger" onClick={(e)=>onSubmit()}>Add</button>
                            </div>
                        </div>
                        </form>
                    </div> 
                    </div> 
                </div> 
                </div> 
            </div> 
            </section> 
            </div>
       </div> 
    </section> 
  </div> 
  <Footer/>
       <Tool/> 
  <div className="control-sidebar-bg" />
</div>		
    </div>
	)
}