import React, {useEffect,useState} from 'react'
import ReactDOM from 'react-dom';
import {Link,useParams, useNavigate} from "react-router-dom";
import Header from '../../../header/Header';
import Sidebarr from '../../../sidebar/Sidebar';
import Tool from '../../../sidebar/Tool';
import Footer from '../../../footer/Footer';
import {EditPrescriptionAction} from "../../../action";
import {useDispatch} from "react-redux";
import { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";
import Dynamicform from './form'; 
import axios from 'axios';
import Editor from "./Editor";
import './ViewPatientReport.css';
const adminToken = localStorage.getItem('token');
const config = {
    headers: { Authorization: `Bearer ${adminToken}` }
};
export default function EditPrescription(){
  const { prescriptionId } = useParams();
  const dispatch =  useDispatch()
  const obj = {}
  const { formState: { errors } } = useForm();
  const [inputField , setInputField] = useState(obj)

const inputsHandler = (e) =>{
    let name = e.target.name; 
    let value = e.target.value;
    inputField[name] = value;
    setInputField(inputField);
}
//const [editorLoaded, setEditorLoaded] = useState(false); 
  const [medicines,setmedicines] = useState("");
  const [bodydosage,setbodydosage] = useState("");
  const [frequency,setfrequency] = useState("");
  const [timePeriod,settimePeriod] = useState("");
  const [refill,setrefill] = useState("");
  const [diagnosis,setdiagnosis] = useState("");
  const [doNotUse,setdoNotUse] = useState("");
  

const onSubmit = () => { 
   const data={
    medicines,
    bodydosage,
    frequency,
    timePeriod,
    refill,
    diagnosis,
    doNotUse
  }
   dispatch(EditPrescriptionAction(data));
   setmedicines("");
   setbodydosage("");
   setfrequency("");
   settimePeriod("");
   setrefill("");
   setdiagnosis("");
   setdoNotUse("");
}
  useEffect(async() => {
    setEditorLoaded(true);
    const res = await axios.get(`/get-prescription-by-id?prescriptionId=${prescriptionId}`,config);
    console.log("---------------------",res);
  }, []);
 const [editorLoaded, setEditorLoaded] = useState(false);
  const [body,setBody] = useState("");
  useEffect(() => {
    setEditorLoaded(true);
  }, []);
	return (
    <div className="sidebar-mini skin-green-light">
        <div><Toaster/></div>
    <div className="wrapper"> 
  		  <Header/> 
  		  <Sidebarr/> 
    <div className="content-wrapper"> 
    <section className="content-header">
      <h1>
       Add New Prescription
      </h1>
      <ol className="breadcrumb">
        <li>
        <Link to="/home" className="fa fa-dashboard"> Home</Link>
        </li>
        <li style={{fontWeight:"bold"}}>Add Prescription</li>
      </ol>
    </section> 
    <section className="content"> 
        <div className="box">
            <div className="box-header with-border"> 
                <div className="box-tools pull-right">
                    <Link
                    to="/patients"
                    type="button"
                    className="btn btn-success" 
                    data-toggle="tooltip"
                    title="view">
                    <i className="fa fa-eye" />
                    {" View Patients"}
                    </Link> 
                </div>
            </div>
            <div className="box-body">
            <section className="content">
            <div className="row">  
                <div className="col-md-12">
                <div className="nav-tabs-custom">
                    <div className="tab-content">
                    <div className="active tab-pane" id="settings">
                    <Dynamicform />
                        
                    </div> 
                    </div> 
                </div> 
                </div> 
            </div> 
            </section> 
            </div>
       </div> 
    </section> 
  </div> 
  <Footer/>
       <Tool/> 
  <div className="control-sidebar-bg" />
</div>		
    </div>
	)
}