import { doctorStatus } from "./Status";
import axios from "../helper/axios";
import toast from 'react-hot-toast';
const adminToken = localStorage.getItem('token');
const config = {
    headers: { Authorization: `Bearer ${adminToken}` }
};
export const AddDoctorAction=(data)=>{
	return async (dispatch)=>{
		dispatch({type:doctorStatus.DOCTOR_REQUEST});
		const res = await axios.post(`/add-doctor/`,data, config);
		console.log(res.data)
		if(res.status===200){    
			const categories= res.data
			dispatch({
				type:doctorStatus.DOCTOR_SUCCESS,
				payload:{
					categories
				}
			})
			toast.success('Doctor added successfully');
		}else{
		toast.error(res?.data?.msg);
	    }
	}
}

export const GetDoctorAction=()=>{
	
	return async (dispatch)=>{
		dispatch({type:doctorStatus.DOCTOR_GET_REQUEST});
		const res = await axios.get(`/get-doctor/`);
		console.log(res.data)
		if(res.status===200){    
			const doctors = res.data
			dispatch({
				type:doctorStatus.DOCTOR_GET_SUCCESS,
				payload:{ doctors }
			})

		}else{
		if(res.status===400){
			dispatch({type:doctorStatus.DOCTOR_GET_FAILED});
			console.log(res.data);
		}
	    }
	}
}

export const EditDoctorAction=(data)=>{
	return async (dispatch)=>{
		dispatch({type:doctorStatus.DOCTOR_UPDATE_REQUEST});
		const res = await axios.post(`/edit-doctor/`,data);
		if(res.status === 200) {    
			const doctors= res.data
			dispatch({
				type: doctorStatus.DOCTOR_UPDATE_SUCCESS,
				payload:{
					doctors
				}
			})
			toast.success('Doctor updated successfully');
		} else {
		if(res.status === 201){
			dispatch({
				type: doctorStatus.DOCTOR_UPDATE_FAILED
			});
			toast.error(res?.data?.msg);
		}
	    }
	}
}

export const DeleteDoctorAction=(data)=>{
	
	return async (dispatch)=>{
		dispatch({type:doctorStatus.DOCTOR_DELETE_REQUEST});
		const res = await axios.post(`/delete-doctor/`,data);
		console.log(res.data)
		if(res.status === 200){    
			const doctors= res.data
			dispatch({
				type:doctorStatus.DOCTOR_DELETE_SUCCESS,
				payload:{ doctors }
			})
			toast.success('Doctor deleted successfully');
		}else{
		if(res.status === 201){
			dispatch({
				type: doctorStatus.DOCTOR_DELETE_FAILED
			});
			toast.error(res?.data?.msg);
			console.log(res.data);
		}
	    }
	}
}