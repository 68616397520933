import React, {useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../../header/Header";
import Sidebarr from "../../../sidebar/Sidebar";
import Tool from "../../../sidebar/Tool";
import Footer from "../../../footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import './ViewPatientReport.css';
import { Chart } from "../../sync/Chart";
// import { DateRange } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";
import subDays from 'date-fns/subDays';

export default function ViewPatientReport() {
  const { patientId } = useParams();
  const {
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state);
  const obj = {};
  const [inputField, setInputField] = useState(obj);

  const inputsHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    inputField[name] = value;
    setInputField(inputField);
  };
  
  const token = useSelector((state) => state?.auth?.token);
  const patientData = useSelector((state) => state?.patient?.data);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [diseases, setDiseases] = useState("");
  const [otherDiseases, setOtherDiseases] = useState("");
  const [medications, setMedications] = useState("");
  const [allergies, setAllergies] = useState("");
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection',
  //   }
  // ]);
  const otherDisease = [];
  const medicineArray = [];
  const allergyArray = [];
  if (otherDiseases && otherDiseases.length > 0) {
    otherDiseases.split(/\s*,\s*/).forEach(function (otherDis) {
      otherDisease.push(otherDis);
    });
  }

  if (medications && medications.length > 0) {
    medications.split(/\s*,\s*/).forEach(function (medication) {
      medicineArray.push(medication);
    });
  }

  if (allergies && allergies.length > 0) {
    allergies.split(/\s*,\s*/).forEach(function (allergy) {
      allergyArray.push(allergy);
    });
  }


  useEffect(() => {
    setEditorLoaded(true);
    const data = patientData.find((obj) => {
      return obj._id === patientId;
    });

    setDiseases(data?.diseases);
    setOtherDiseases(data?.otherDiseases);
    setMedications(data?.medication);
    setAllergies(data?.allergies);
  }, []);

//   const handleSelect = async (input) => {
//     console.log("----input----",input);
//     // console.log(input.selection.endDate,"----input----",input.selection.startDate);
//  }

  // const selectionRange = {
  //   startDate: new Date(),
  //   endDate: null,
  //   key: 'selection',
  // }

  return (
    <div className="sidebar-mini skin-gr0een-light">
      <div>
        <Toaster />
      </div>
      <div className="wrapper">
        <Header />
        <Sidebarr />
        <div className="content-wrapper">
          <section className="content-header">
            <h1>View Patient Reports</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/home">
                  <i className="fa fa-dashboard" /> Home
                </a>
              </li>
              <li>
                <a href="/patients">Manage patient</a>
              </li>
              <li style={{fontWeight:"bold"}}>View patient reports</li>
            </ol>
          </section>
          <section className="content">
            <div className="box">
              <div className="box-header with-border">
                <div className="box-tools pull-left">
                {/* <label>  */}
                {/* <DateRangePicker
                ranges={[]} 
                hoverRange={date => [subDays(date, 1)]} 
                placeholder="Select Date Range"
                showOneCalendar
                onOk={item => handleSelect(item)}
                /> */}
                {/* <DateRangePicker showOneCalendar /> */}
                    {/* <DateRange
                    isSelected={false}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    onChange={item => handleSelect(item)}
                    /> */}
                  {/* </label> */}
                </div>

                <div className="box-tools pull-right">
                  <Link
                    to="/patients"
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    title="Back"
                  >
                    {"Go Back"}
                  </Link>
                </div>
              </div>

              <div className="box-body">
                <section className="content">
                  <div className="row">
                    <div className="col-md-10">
                    <Chart/>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <Tool />
        <div className="control-sidebar-bg" />
      </div>
    </div>
  );
}
