import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../helper/axios";

const DeletePatient = () => {
    const userId = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();
    console.log("userId", userId._id);

    const deleteAccount = async () => {
        try {
            const { data } = await axios.post(`${baseUrl}api/deletepatient`, {
                id: userId?._id
            });
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            navigate("/delete-patient-login");
        } catch (err) {
            console.log(err);
            toast.error("Something went wrong!");
        }
    }

    return (
        <div className="hold-transition login-page" style={{ position: "absolute", minHeight: "100%", minWidth: "100%", backgroundImage: "radial-gradient(white, rgb(171 168 168))" }}>
            <div>
                <Toaster />
            </div>
            <div className="login-box">

                <div className="login-box-body" style={{ borderRadius: "4%" }}>

                    {/* <div className="login-logo">
                        <a href="../../index2.html">
                            <a
                                aria-current="page"
                                className="MobileHeaderTop__logo active"
                                href="#"
                            >
                                <img src="dist/img/avatar5.png" style={{ height: "100px" }}></img>
                            </a>
                        </a>
                    </div> */}
                    <p className="login-box-msg" style={{ fontWeight: "bold", fontSize: "17px", fontFamily: "sans-serif" }}>
                        If you want to delete all account data then press delete button.
                    </p>
                    <div className="row">
                        <div className="col-xs-4" style={{ left: "137px" }}>
                            <button
                                type="button"
                                className="btn btn-warning btn-block btn-flat"
                                style={{
                                    backgroundColor: "#DF1721",
                                    borderColor: "#690E14",
                                    borderRadius: "7px",
                                }}
                                onClick={(e) => deleteAccount()}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeletePatient;